<template>
  <div>
    <!-- Filters -->
    <v-row>
      <v-col cols="4">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              :label="$t('Select Date')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker :max="maxDate" v-model="date" @input="menu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="state"
          v-model="selectedState"
          item-text="text"
          item-value="id"
          :label="$t('State')"
          solo
          @change="getStudentAttendance()"
        ></v-select
      ></v-col>
      <v-col cols="4">
        <v-select
          v-model="selectedSubjects"
          :items="subjects"
          item-text="name"
          item-value="id"
          @change="getStudentAttendance()"
          chips
          :label="$t('subject')"
          multiple
          solo
        ></v-select>
      </v-col>
    </v-row>
    <!-- icon print -->
    <v-row>
      <v-col sm="8" md="8" cols="12"></v-col>
      <v-col sm="4" md="4" cols="12">
        <div style="text-align: right">
          <v-icon
            class="main-color"
            large
            @click="openPrintDialog = true"
            :title="$t('Print')"
            >print</v-icon
          >
        </div>
      </v-col>
    </v-row>
    <!-- table -->
    <v-row v-if="headers.length > 0">
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-left"
                  v-for="(item, index) in headers"
                  :key="index"
                >
                  {{ $t(item) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(lessons, date) in items" :key="date">
                <td>{{ date }}</td>
                <td
                  v-for="(lessonObject, lessonIndex) in lessons"
                  :key="lessonIndex"
                >
                  <p
                    class="subjectName mb-0"
                    v-if="
                      selectedState == '' ||
                        (selectedState != '' && lessonObject.absent != null)
                    "
                  >
                    {{ lessonObject.subject_name }}

                    <v-menu
                      offset-x
                      max-width="300px"
                      v-if="lessonObject.comment && lessonObject.subject_name"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                          <v-icon color="#7297ff">mdi-alert-circle</v-icon>
                        </v-btn>
                      </template>
                      <v-list style="padding-top: 0">
                        <v-list-item style="background-color: gray">
                          <p class="mb-0" style="color: #ffffff">
                            {{ $t("Teacher Comment") }}
                          </p>
                        </v-list-item>
                        <v-list-item>
                          <p class="mb-0">
                            {{ lessonObject.comment }}
                          </p>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </p>
                  <v-icon
                    v-if="lessonObject.absent == 0 && lessonObject.subject_name"
                    color="#7297ff"
                    >check</v-icon
                  >
                  <v-icon
                    v-if="lessonObject.absent == 1 && lessonObject.subject_name"
                    color="error"
                    >close</v-icon
                  >
                  <span
                    v-if="
                      lessonObject.absent == null &&
                        lessonObject.subject_name &&
                        selectedState == ''
                    "
                    class="notAvailable"
                    >{{ $t("N/A") }}</span
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="text-center">
        <div style="font-size:20px;font-weight:bold">
          {{ $t("No data available") }}
        </div>
      </v-col>
    </v-row>

    <!-- dialog Print -->
    <v-dialog v-model="openPrintDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">{{
          $t("Student Attendance")
        }}</v-card-title>

        <v-card-text>
          <v-container>
            <v-form v-model="valid" ref="form">
              <v-row>
                <v-col cols="12">
                  <v-select
                    :items="state"
                    v-model="printData.state"
                    item-text="text"
                    item-value="id"
                    :label="$t('State')"
                    solo
                  ></v-select
                ></v-col>
                <v-col cols="12">
                  <v-select
                    v-model="printData.subject"
                    :items="subjects"
                    item-text="name"
                    item-value="id"
                    chips
                    :label="$t('subject')"
                    multiple
                    solo
                  ></v-select>
                </v-col>
                <!-- week -->
                <v-col cols="12">
                  <label for="ClassName">{{ $t("Week") }}: </label>
                  <v-select
                    :items="weeks"
                    item-text="name"
                    item-value="id"
                    v-model.trim="printData.selectedWeekId"
                    :rules="[validationRules.required]"
                    dense
                    outlined
                  >
                    <template slot="selection" slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ data.item.name }} {{ data.item.from }} :
                      {{ data.item.to }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ data.item.name }} {{ data.item.from }} :
                      {{ data.item.to }}
                    </template>
                  </v-select>
                </v-col>
                <!-- <v-col cols="6">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="printData.dateFrom"
                        label="Date From"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="printData.dateFrom"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="printData.dateTo"
                        label="Date To"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="printData.dateTo"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col> -->
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="closePrintDial()">{{
            $t("Close")
          }}</v-btn>
          <v-btn
            class="modal-btn-save"
            @click="print()"
            :loading="loading"
            :disabled="loading"
            >{{ $t("Print") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  mixins: [validationMixin],
  name: "studentAttendance",
  data() {
    return {
      // valid: false,
      date: "",
      maxDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate(),
      menu: false,
      menu1: false,
      menu2: false,

      state: [
        {
          text: "",
          id: "",
        },
        {
          text: this.$i18n.t("Absent"),
          id: "absent",
        },
        {
          text: this.$i18n.t("Attended"),
          id: "attended",
        },
      ],
      selectedState: "",
      subjects: [],
      selectedSubjects: [],
      loading: false,
      openPrintDialog: false,
      headers: [],
      items: [],
      weeks: [],
      valid: false,
      printData: {
        state: "",
        subject: [],
        selectedWeekId: "",
      },
    };
  },
  watch: {
    date: {
      handler() {
        this.getStudentAttendance();
      },
    },
  },
  methods: {
    closePrintDial() {
      this.printData = {
        state: "",
        subject: [],
        selectedWeekId: "",
      };
      this.openPrintDialog = false;
      this.$refs.form.resetValidation();
    },
    print() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.loading = true;
        axios
          .post(
            this.getApiUrl + "/attendance/printStudentAttendance",
            {
              weekId: this.printData.selectedWeekId,
              // dateTo: this.printData.dateTo,
              state: this.printData.state,
              subject: this.printData.subject,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            var link = response.data.data;
            console.log(link);
            this.loading = false;

            // this.valid = false;

            this.closePrintDial();
            window.open(link, "_blank");
          });
        this.openPrintDialog = false;
      }
    },
    getStudentAttendance() {
      axios
        .post(
          this.getApiUrl + "/attendance/getStudentsAttendanc",
          {
            date: this.date,
            state: this.selectedState,
            subject: this.selectedSubjects,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.items = response.data.data.attendence;
            this.headers = response.data.data.lessons;
          }
        });
    },
    getSubjects() {
      axios
        .get(
          this.getApiUrl + "/attendance/getSubjectsOfstudentSchedule",

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.subjects = response.data.data;
          }
        });
    },
    getWeeks() {
      axios
        .get(
          this.getApiUrl + "/attendance/getWeeks",

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.weeks = response.data.data;
          }
        });
    },
  },
  mounted() {
    this.getStudentAttendance();
    this.getSubjects();
    this.getWeeks();
  },
};
</script>

<style lang="scss" scoped>
.notAvailable {
  color: #ffb400;
}
</style>
